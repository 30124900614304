import React, { useEffect } from 'react';
import './Homepage.scss';
import '../../assets/css/animate.css';
import '../../assets/css/icomoon.css';
import '../../assets/css/simple-line-icon.css';

import img1 from '../../assets/images/about_us.jpg'
import logo from '../../assets/images/logo.svg'
import img7 from '../../assets/images/wgt.jpg'
import img8 from '../../assets/images/ecommpay.jpg'
import frstSlide from '../../assets/images/1.webp'
import scndSlide from '../../assets/images/3.webp'
import thrdSlide from '../../assets/images/4.webp'
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';	
import 'swiper/css';
import 'swiper/css/navigation';

const HomePage = () => {

  useEffect(() => {

	const servicesAnimate = () => {
		const elementsToAnimate = document.querySelectorAll('#fh5co-services .to-animate');
		elementsToAnimate.forEach((el, index) => {
		  setTimeout(() => {
			el.classList.add('fadeInUp', 'animated');
		  }, index * 150);
		});
	  };
  
	  const servicesWayPoint = () => {
		const pressSection = document.getElementById('fh5co-services');
		
		if (pressSection) {
		  const observer = new IntersectionObserver(
			(entries, observer) => {
			  entries.forEach((entry) => {
				if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
					servicesAnimate();
				 	entry.target.classList.add('animated');
				 	observer.unobserve(entry.target);
				}
			  });
			},
			{ threshold: 0.1 }
		  );
  
		  observer.observe(pressSection); 
		}
	  };

	const featuresAnimate = () => {
		const elementsToAnimate = document.querySelectorAll('#fh5co-features .to-animate');
		elementsToAnimate.forEach((el, index) => {
		  setTimeout(() => {
			el.classList.add('fadeInUp', 'animated');
		  }, index * 150);
		});
	  };
  
	  const featuresWayPoint = () => {
		const pressSection = document.getElementById('fh5co-features');
		
		if (pressSection) {
		  const observer = new IntersectionObserver(
			(entries, observer) => {
			  entries.forEach((entry) => {
				if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
					featuresAnimate();
				  entry.target.classList.add('animated');
				  observer.unobserve(entry.target);
				}
			  });
			},
			{ threshold: 0.4 }
		  );
  
		  observer.observe(pressSection); 
		}
	  };
   
	  const cutomDevAnimate = () => {
		const elementsToAnimate = document.querySelectorAll('#fh5co-custom-develop .to-animate');
		elementsToAnimate.forEach((el, index) => {
		  setTimeout(() => {
			el.classList.add('fadeInUp', 'animated');
		  }, index * 150);
		});
	  };
  
	  const cutomDevAnimatePoint = () => {
		const pressSection = document.getElementById('fh5co-custom-develop');
		
		if (pressSection) {
		  const observer = new IntersectionObserver(
			(entries, observer) => {
			  entries.forEach((entry) => {
				if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
					cutomDevAnimate();
				  entry.target.classList.add('animated');
				  observer.unobserve(entry.target);
				}
			  });
			},
			{ threshold: 0.4 }
		  );
  
		  observer.observe(pressSection); 
		}
	  };

    const pressAnimate = () => {
      const elementsToAnimate = document.querySelectorAll('#fh5co-projects .to-animate');
      elementsToAnimate.forEach((el, index) => {
        setTimeout(() => {
          el.classList.add('fadeInUp', 'animated');
        }, index * 150);
      });
    };

    const pressWayPoint = () => {
      const pressSection = document.getElementById('fh5co-projects');
      
      if (pressSection) {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting && !entry.target.classList.contains('animated')) {
                pressAnimate();
                entry.target.classList.add('animated');
                observer.unobserve(entry.target);
              }
            });
          },
          { threshold: 0.4 }
        );

        observer.observe(pressSection); 
      }
    };

	servicesWayPoint()
	featuresWayPoint()
    pressWayPoint();
	cutomDevAnimatePoint()
  }, []);

  return (
    <div className="pageContainer">
      <nav id="mainNavbar" className={`navbar navbar-expand-lg`}>
			<div className="container">  
				<a className="navbar-brand" href="#">
					<img src={logo} alt="Logo" style={{ height: '80px' }} />
				</a>

				<i 
				  className="fas fa-bars fh5co-nav-toggle"
				  style={{ fontSize: '32px' }}
				  type="button"
				  data-bs-toggle="collapse"
				  data-bs-target="#navbarNav"
				  aria-controls="navbarNav"
				  aria-expanded="false"
				  aria-label="Toggle navigation"
				/>

	
				<div className="collapse navbar-collapse" id="navbarNav">
				<ul className="navbar-nav ms-auto text-end">
					<li className={`nav-item`}>
						<a className="nav-link" href="#fh5co-about-us">
							About us
						</a>
					</li>
					<li className={`nav-item`}>
						<a className="nav-link" href="#fh5co-services">
							Services
						</a>
					</li>
					<li className={`nav-item`}>
						<a className="nav-link" href="#fh5co-features">
							Features
						</a>
					</li>
					<li className={`nav-item`}>
						<a className="nav-link" href="#fh5co-custom-develop">
							Custom development
						</a>
					</li>
					<li className={`nav-item`}>
						<a className="nav-link" href="#fh5co-projects">
							Our projects
						</a>
					</li>
				</ul>
				</div>
			</div>
	  </nav>

	
	  <Swiper 
	  	navigation={true} 
		modules={[Navigation, Autoplay]}
		className="mySwiper"
		autoplay={{
			delay: 2500,
		}}
		>
        <SwiperSlide><img src={frstSlide}/></SwiperSlide>
        <SwiperSlide><img src={scndSlide}/></SwiperSlide>
        <SwiperSlide><img src={thrdSlide}/></SwiperSlide>
      </Swiper>

	<div id="fh5co-about-us" data-section="about">
		<div className="container">
			<div className="row" id="about-us">
				<div className="col-md-12 section-heading text-center">
					<h2 className="to-animate">About Us</h2>
				</div>
				
				<img className="col-md-7 to-animate" src={img1} />
				
				<div className="col-md-5 to-animate">
					<p>
						WebTechnologies is a young and ambitious team dedicated to providing your projects with new opportunities and unforgettable experiences. We don’t just deliver business solutions—we create a story and unique experiences that strengthen the bond between your company and its customers.
						<br/>
						Our main focus is on online stores, corporate websites, and mobile applications, but we also offer a range of additional services including game development, 3D modeling, custom design, and Telegram bots. With a small yet highly skilled team, we ensure quick turnaround times and a flexible approach, bringing your ideas to life as efficiently as possible.
						<br/>
						We work with clients worldwide and communicate through whichever platform is most convenient for you, whether it’s Telegram, WhatsApp, Zoom, or any other. Our aim is not only to provide quality services but also to make the collaboration process as smooth and transparent as possible.
					</p>
				</div>
			</div>
		</div>
	</div>

	<div id="fh5co-services" data-section="services">
		<div className="container">
			<div className="row row-bottom-padded-sm">
				<div className="col-md-12 section-heading text-center">
					<h2 className="to-animate" style={{ opacity: 0 }}>Our Services</h2>
					<div className="row justify-content-center">
						<div className="col-md-8 text-center to-animate" style={{ opacity: 0 }}>
						<h3>We offer end-to-end IT project development solutions - from design to integration and creation of mobile and web applications. Our services cover all stages of development to ensure you get the best results.</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="row	justify-content-center">
				<div className="col-md-4">
					<div className="box to-animate">
						<div className="icon colored-1"><span><i className="icon-mustache"></i></span></div>
						<h3>Web Development</h3>
						<p>Development of modern websites and web applications using advanced technologies such as Spring Boot, React, .NET, HTML, CSS, and JavaScript.</p>
					</div>
					<div className="box to-animate">
						<div className="icon colored-4"><span><i className="icon-heart"></i></span></div>
						<h3>Mobile App Development</h3>
						<p>Creating mobile applications with native or cross-platform technologies, customized for different devices and operating systems.</p>
					</div>
				</div>
				<div className="col-md-4">
					<div className="box to-animate">
						<div className="icon colored-2"><span><i className="icon-screen-desktop"></i></span></div>
						<h3>UI/UX Design</h3>
						<p>Designing user interfaces and improving user experience. Website, app and digital content design including logos, banners and brandbooks.</p>
					</div>
					<div className="box to-animate">
						<div className="icon colored-5"><span><i className="icon-rocket"></i></span></div>
						<h3>3D Modeling &amp; Interactive Solutions</h3>
						<p>Creating 3D models and animations using Blender and Unity. Developing interactive 3D solutions for websites using Three.js.</p>
					</div>
				</div>

			</div>
		</div>
	</div>
	
	<div id="fh5co-features" data-section="features">
		<div className="container">
			<div className="row">
				<div className="col-md-12 section-heading text-center">
					<h2 className="single-animate animate-features-1 to-animate" style={{ opacity: 0 }} >Features</h2>
					<div className="row justify-content-center">
						<div className="col-md-8 single-animate animate-features-2 to-animate text-center" style={{ opacity: 0 }}>
							<h3>Our solutions stand out by utilizing state-of-the-art technology and advanced development approaches. We create adaptive, interactive and secure designs that meet your business goals.</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-4 col-sm-6 fh5co-service to-animate">
					<div className="fh5co-icon"><i className="icon-present"></i></div>
					<div className="fh5co-desc">
						<h3>Utilize modern technologies</h3>
						<p>Working with proven and cutting edge tools such as Unity, React, .NET, Three.js, and Spring Boot, ensuring high quality development.</p>
					</div>  
				</div>
				<div className="col-md-4 col-sm-6 fh5co-service to-animate">
					<div className="fh5co-icon"><i className="icon-eye"></i></div>
					<div className="fh5co-desc">
						<h3>Adaptive and cross-platform design</h3>
						<p>Projects that look and work great on any device, including cell phones, tablets, and computers.</p>
					</div>
				</div>
				<div className="col-md-4 col-sm-6 fh5co-service to-animate">
					<div className="fh5co-icon"><i className="icon-crop"></i></div>
					<div className="fh5co-desc">
						<h3>Integration with popular services and APIs</h3>
						<p>Easy integration with external services such as Stripe for payments and Telegram for bot creation.</p>
					</div>
				</div>
				<div className="col-md-4 col-sm-6 fh5co-service to-animate">
					<div className="fh5co-icon"><i className="icon-speedometer"></i></div>
					<div className="fh5co-desc">
						<h3>3D and interactive solutions</h3>
						<p>Interactive 3D models and configurators to enhance the visual experience on websites.</p>
					</div>  
				</div>
				<div className="col-md-4 col-sm-6 fh5co-service to-animate">
					<div className="fh5co-icon"><i className="icon-heart"></i></div>
					<div className="fh5co-desc">
						<h3>Security and performance</h3>
						<p>High security standards and performance optimization to keep your applications running reliably.</p>
					</div>
				</div>

			</div>
		</div>
	</div>


	<div id="fh5co-custom-develop" data-section="custom-develop">
		<div className="container">
			<div className="row">
				<div className="col-md-12 section-heading text-center">
					<h2 className="single-animate to-animate" style={{ opacity: 0 }}>Why Professional Development is Better Than Website Builders?</h2>
					<div className="row justify-content-center">
						<div className="col-md-8 single-animate to-animate text-center" style={{ opacity: 0 }}>
							<p>Professional development offers tailored, scalable solutions that align perfectly with your business goals, unlike generic website builders. While builders provide limited customization and features, professional developers create unique, optimized websites with advanced functionality and superior user experience. This personalized approach ensures long-term growth and adaptability for your brand.</p>
							
							<h3>Custom Solutions for Business Needs</h3>
							Complete adaptation to the unique requirements and processes of your company.<br/>
							Seamless integration with any internal systems.
							<br/>
							<br/>

							<h3>Scalability and High Performance</h3>
							Support for high loads and flexible scalability.<br/>
							Quick adaptation to changing business demands.
							<br/>
							<br/>
							<h3>Enhanced Security and Compliance</h3>
							Customizable data protection and adherence to industry standards.<br/>
							Individualized security measures at all levels.
							<br/>
							<br/>
							<h3>SEO Flexibility and Adaptability</h3>
							Fine-tuning of all website aspects to improve search visibility.<br/>
							Full optimization for all devices and screen sizes.
							<br/>
							<br/>
							<h3>Unlimited Development and Support</h3>
							Ability to add new features and modify existing ones at any time.<br/>
							Ongoing support and project maintenance.
							<br/>
							<br/>
							<h3>Competitive Advantage and Innovation</h3>
							Development of unique features that set your business apart from competitors. <br/>
							Rapid implementation of the latest technologies to stay ahead in the market.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div id="fh5co-projects" data-section="projects">
		<div className="container">
			<div className="row">
				<div className="col-md-12 section-heading text-center">
					<h2 className="single-animate animate-press-1 to-animate" style={{ opacity: 0 }}>Our projects</h2>
					<div className="row justify-content-center">
						<div className="col-md-8 col-md-offset-2 subtext single-animate animate-press-2 text-center to-animate" style={{ opacity: 0 }}>
							<h3>Our projects showcase innovative solutions and creative approaches across various industries. Each initiative reflects our commitment to excellence, sustainability, and impactful results.</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<div className="fh5co-projects-item to-animate">
						<div className="fh5co-projects-img" style={{ backgroundImage: `url(${img7})` }}>
						</div>
						<div className="fh5co-projects-text">
							<h3 className="h2 fh5co-projects-title">WeGoTonight<span className="fh5co-border"></span></h3>
							<p>Riga Nightlife Guide: Development of a platform for reviewing nightlife venues and events in Riga, including a website with a unique design, realized in the shortest possible time (1 week). The project includes multi-lingual support (Russian, English, Latvian) and integration with Telegram-bot for easy access to information. A flexible architecture and a convenient admin panel for content management were created.</p>
						</div>
					</div>
		
				</div>

				<div className="col-md-6">
			
					<div className="fh5co-projects-item to-animate">
						<div className="fh5co-projects-img" style={{ backgroundImage: `url(${img8})` }}>
						</div>
						<div className="fh5co-projects-text">
							<h3 className="h2 fh5co-projects-title">Corporate quest platform<span className="fh5co-border"></span></h3>
							<p>Creation of an interactive platform for conducting a quest at a corporate event. Corporate design standards are taken into account, an admin panel for data collection and counting of answers is developed. A scalable architecture was implemented for stable operation at peak loads, with support at all stages of launch.</p>
							<br/>
						</div>
					</div>
		
				</div>

			</div>
		</div>
	</div>
	

	<footer id="footer" role="contentinfo">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<p className="copyright text-center">&copy; 2024 WebTechnologies. All Rights Reserved.</p>
					<p className="text-center"><b>Contact us:</b> webtechnofor@gmail.com</p>
				</div>
			</div>
			{/* <div className="row">
				<div className="col-md-12 text-center">
					<ul className="social social-circle">
						<li><a href="#"><i className="icon-facebook"></i></a></li>
						<li><a href="#"><i className="icon-linkedin"></i></a></li>
						<li><a href="#"><i className="icon-instagram"></i></a></li>
		
					</ul>
				</div>
			</div> */}
		</div>
	</footer>

    </div>
  );
};

export default HomePage;
