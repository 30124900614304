import React, { useState, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import HomePage from './layouts/homepage/HomePage';

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Routes>
        <Route path="/"  element={<HomePage />} />
    </Routes>      
  );
};

export default App;